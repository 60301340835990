import { ref, child, get, push, update, set, remove, onValue } from "firebase/database";
import db from "@/services/Firebase.js";
// import { v4 as uuidv4 } from 'uuid';


const votings = ref(db, "/votings");

class VotingDataService {
  getAll() {
    return get(votings);
  }

  new() {
      return {
        id: null,
        created: (new Date()).toISOString(),
        active: true,
        visible: true,
        votes: {
            A: 0,
            B: 0,
            C: 0,
            D: 0
        },
        cards: {}
      }
  }

  async create(voting) {
    let record = await push(votings, voting) 
    this.update(record.key, {id: record.key})
    return record.key;
  }

  async get(key) {
    const snapshot = await get(child(votings, key));
    return (snapshot.exists()?snapshot.val():null);  
  }

  async set(key, value) {
    return await set(child(votings, key), value)
  }

  async update(key, value) {
    return await update(child(votings, key), value);
  }

  async delete(key) {
    return await remove(child(votings, key));
  }

  onValue(key, $callback) {
      return onValue(child(votings, key), (snapshot) => {
        const data = snapshot.val();
        $callback(data);
      })
  } 
}

export default new VotingDataService();