import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'

const firebaseConfig = {
  apiKey: "AIzaSyCrdxf2HnvfsrDLd6RySCqqDxR2ewyMv4c",
  authDomain: "schoolars.firebaseapp.com",
  databaseURL: "https://schoolars-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "schoolars",
  storageBucket: "schoolars.appspot.com",
  messagingSenderId: "436096972429",
  appId: "1:436096972429:web:981cc355918d369f5594c4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

export default db;
